export default [

  /*
   ****机构用户开始
   */
  // 机构用户首页
  {
    path: '/home',
    name: 'home',
    // redirect: '/home',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/user-data')
  },
  // 个人信息
  {
    path: '/userData',
    name: 'userData',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/user-data')
  },
  // 更多系统消息
  {
    path: '/list',
    name: 'user-list',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/common/list')
  },
  // 详情
  {
    path: '/details',
    name: 'user-details',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/common/details')
  },
  // 账户
  {
    path: '/userAccount',
    name: 'userAccount',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/admin/user/user-account')
  },
  // 考生报名审核
  {
    path: '/examCheck/:module',
    name: 'examCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/exam-check')
  },
  // 工作量审核
  {
    path: '/workloadCheck',
    name: 'workloadCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/workload-check')
  },
  // 照片审核
  {
    path: '/imageCheck',
    name: 'imageCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/image-check')
  },
  // 学历审核
  {
    path: '/educationCheck',
    name: 'educationCheck',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/education-check')
  },
  //审核任务分配
  {
    path: '/taskDistribution',
    name: 'taskDistribution',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/task-distribution')
  },
  //部门
  {
    path: '/department',
    name: 'user-department',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/department')
  },
  //部门子账号
  {
    path: '/departChildAccount',
    name: 'departChildAccount',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/depart-childAccount')
  },


  // 成绩查询
  {
    path: '/scoreQuery',
    name: 'scoreQuery',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/score-query')
  },
  // 证书查询
  {
    path: '/recordCertificate',
    name: 'recordCertificate',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/record-certificate')
  },
  // 证书查询
  {
    path: '/scoreSend',
    name: 'scoreSend',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/score-send')
  },
  // 工单提交
  {
    path: '/workorder',
    name: 'workorder',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/work-order/workorder')
  },
  // 我的工单
  {
    path: '/workorderList',
    name: 'workorderList',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/work-order/workorder-list')
  },
  {
    path: '/workorderList/:id',
    name: 'workorderListId',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/user/work-order/workorder-list')
  },{
    path: '/workOrderHandle',
    name: 'workOrderHandle',
    component: () => import("@/views/pages/admin/workorder/order/workOrderHandle.vue"),
  },
  // 考试详情
  {
    path: '/examIndex',
    name: 'userExamIndex',
    // meta: {
    //   authRequired: true,
    // },
    component: () => import('../views/pages/admin/exam/index')
  },
  /*
   ***机构用户结束
   */

  // 模板原来未改的部分
 
  {
    path: '/ui/buttons',
    name: 'Buttons',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/buttons')
  },
  {
    path: '/ui/alerts',
    name: 'Alerts',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/alerts')
  },
  {
    path: '/ui/grid',
    name: 'Grid',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/grid')
  },
  {
    path: '/ui/cards',
    name: 'Cards',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/cards')
  },
  {
    path: '/ui/carousel',
    name: 'Carousel',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/carousel')
  },
  {
    path: '/ui/dropdowns',
    name: 'Dropdowns',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/dropdowns')
  },
  {
    path: '/ui/images',
    name: 'Images',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/images')
  },
  {
    path: '/ui/modals',
    name: 'Modals',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/modals')
  },
  {
    path: '/ui/rangeslider',
    name: 'Range - slider',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/rangeslider')
  },
  {
    path: '/ui/progressbar',
    name: 'Progressbar',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/progressbars')
  },
  {
    path: '/ui/sweet-alert',
    name: 'Sweet-alert',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/sweet-alert')
  },
  {
    path: '/ui/tabs-accordion',
    name: 'Tabs & Accordion',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/tabs-accordions')
  },
  {
    path: '/ui/typography',
    name: 'Typography',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/typography')
  },
  {
    path: '/ui/video',
    name: 'Video',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/video')
  },
  {
    path: '/ui/general',
    name: 'General',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/general')
  },
  {
    path: '/ui/lightbox',
    name: 'Lightbox',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/lightbox')
  },
  {
    path: '/ui/notification',
    name: 'Notification',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/notification')
  },
  {
    path: '/ui/rating',
    name: 'Rating',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/rating')
  },
  {
    path: '/ui/session-timeout',
    name: 'Session Timeout',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/ui/session-timeout')
  },
  {
    path: '/form/elements',
    name: 'Form Elements',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/elements')
  },
  {
    path: '/form/validation',
    name: 'Form validation',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/validation')
  },
  {
    path: '/form/advanced',
    name: 'Form Advanced',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/advanced')
  },
  {
    path: '/form/editor',
    name: 'CK Editor',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/ckeditor')
  },
  {
    path: '/form/uploads',
    name: 'File Uploads',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/uploads')
  },
  {
    path: '/form/wizard',
    name: 'Form Wizard',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/wizard')
  },
  {
    path: '/form/mask',
    name: 'Form Mask',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/mask')
  },


  {
    path: '/test',
    name: 'test',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/test')
  },
  {
    path: '/server',
    name: 'server',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/server')
  },
  {
    path: '/cache',
    name: 'cache',
    meta: {
      authRequired: true
    },
    component: () => import('../views/pages/forms/cache')
  },

  {
    path: '/examinationAuthority',
    name: 'ExaminationAuthority',
    meta: {
      authRequired: true
    },
    component: () => import('@/views/pages/user/distribution-exam/modeDepartment/examination-authority.vue')
  },
  {
    path: '/examinationAuthorityRange',
    name: 'examinationAuthorityRange',
    meta: {
      authRequired: true
    },
    component: () => import('@/views/pages/user/distribution-exam/modeRange/examination-authority.vue')
  },{
    path: '/checkNewWin',
    name: 'checkNewWinOrg',
    component: () => import("@/views/pages/admin/enroll/check-new-win.vue"),
  },
  {
    path: '/reportView',
    name: 'reportView1',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/enroll/report-view')
  },
  {
    path: '/reportView/professional',
    name: 'reportViewProfessional1',
    // meta: {
    //     authRequired: true,
    // },
    component: () => import('../views/pages/user/enroll/report-professional-view')
  },
  {
    path: '/user/informationCollaboration',
    name: 'userInformationCollaboration',
    component: () => import('../views/pages/user/information-collaboration')
  },
  {
    path: '/user/informationCollaborationApply',
    name: 'informationCollaborationApply',
    component: () => import('../views/pages/user/information-apply')
  },
  // // 考试报名-模板
  // {
  //   path: '/admin/examEnrollMould2',
  //   name: 'Mould',
  //   // meta: {
  //   //     authRequired: true,
  //   // },
  //   component: () => import('../views/pages/admin/exam/exam-enroll-mould2')
  // },
]
